import React, { useState } from "react"
import { Formik } from "formik"
import { Helmet } from "react-helmet"

import { dataURItoBlob } from "../../utils/form-data"
import {
  submitForm,
  handleSubmitResponse,
  jsonToFormData,
} from "../../services"

import { LCard, Col2, Col2Constant, FieldSet } from "../../style/containers"

import EmploymentHistory from "../../components/EmploymentHistory"
import Layout from "../../components/layout"
import SButton from "../../components/button"
import { CheckboxGroup } from "../../components/form/checkbox"
import {
  initialValues,
  validationSchema,
} from "../../components/PreRegistration"
import Form from "../../components/form/FormHelper"
import {
  FormikCheckbox,
  FormikTabs,
  FormikInput,
  FormikText,
} from "../../components/form"
import { TagSelector } from "../../components/form/tags"

const PreRegistration = () => {
  const [errorText, setErrorText] = useState([])

  const onSubmit = async (values, actions) => {
    if (signatureDrawn) {
      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/ false || !!document.documentMode

      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia

      let file

      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png")
      const blobContent = dataURItoBlob(dataURL)
      const formData = jsonToFormData(values)

      if (isEdge || isIE) {
        file = new Blob([blobContent], "signature.png", {
          type: "image/png",
        })
      } else {
        file = new File([blobContent], "signature.png", { type: "image/png" })
      }

      formData.append("signature", file)

      let res = await submitForm(formData, "")
      handleSubmitResponse(res, setErrorText)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pre-registration Form</title>
      </Helmet>
      <LCard>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values, isSubmitting }) => (
            <Form>
              <h1>Pre-registration Form</h1>
              <h2>Personal details</h2>
              <FieldSet>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="personal_details.title"
                  label="Title"
                  tabs={[
                    { name: "Mr", label: "Mr" },
                    { name: "Mrs", label: "Mrs" },
                    { name: "Miss", label: "Miss" },
                    { name: "Ms", label: "Ms" },
                  ]}
                />
                <Col2>
                  <FormikInput
                    name="personal_details.first_name"
                    type="text"
                    label="First name"
                  />
                  <FormikInput
                    name="personal_details.surname"
                    type="text"
                    label="Surname"
                  />
                  <FormikInput
                    name="personal_details.email"
                    type="email"
                    label="Email"
                  />
                  <FormikInput
                    name="personal_details.mobile"
                    type="tel"
                    label="Mobile"
                  />
                  <FormikInput
                    label="Landline"
                    name="personal_details.landline"
                    type="tel"
                    optional={true}
                  />
                  <FormikInput
                    name="personal_details.national_insurance"
                    type="text"
                    label="National Insurance number"
                  />
                  <FormikText label="Address" name="personal_details.address" />
                  <FormikTabs
                    setFieldValue={setFieldValue}
                    name="personal_details.smoker"
                    label="Are you a smoker?"
                    tabs={[
                      { name: "Yes", label: "Yes" },
                      { name: "No", label: "No" },
                    ]}
                  />
                  <FormikTabs
                    setFieldValue={setFieldValue}
                    name="personal_details.safety_boots"
                    label="Do you own safety boots?"
                    tabs={[
                      { name: "Yes", label: "Yes" },
                      { name: "No", label: "No" },
                    ]}
                  />
                  <br />
                  {values.personal_details.safety_boots === "No" ? (
                    <Col2Constant>
                      <FormikInput
                        name="personal_details.safety_boots_size"
                        type="text"
                        label="What size are your feet?"
                      />
                    </Col2Constant>
                  ) : (
                    ""
                  )}
                  <TagSelector
                    label="What are your current methods of transport?"
                    name="method_of_transport"
                    options={["Bike", "Bus", "Car", "Walk", "Other"]}
                  />
                </Col2>
              </FieldSet>
              <h2>Availability</h2>
              <Col2Constant>
                <Col2Constant>
                  <FormikInput
                    name="availability.available_from"
                    type="text"
                    label="Date available to start work"
                  />
                </Col2Constant>
              </Col2Constant>
              <Col2>
                <CheckboxGroup>
                  <b>Shift types available for</b>
                  <FormikCheckbox
                    label="Days"
                    name="availability.shifts.days"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Nights"
                    name="availability.shifts.nights"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Weekends"
                    name="availability.shifts.weekends"
                    withoutError={true}
                  />
                </CheckboxGroup>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="availability.driving_licence"
                  label="Do you have a valid driving licence?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                {values.availability.driving_licence === "Yes" ? (
                  <FormikTabs
                    setFieldValue={setFieldValue}
                    name="availability.own_transport"
                    label="Do have your own transport?"
                    tabs={[
                      { name: "Yes", label: "Yes" },
                      { name: "No", label: "No" },
                    ]}
                  />
                ) : (
                  ""
                )}
                {values.availability.own_transport === "Yes" ? (
                  <CheckboxGroup>
                    <b>Transport methods owned</b>
                    <FormikCheckbox
                      label="Van"
                      name="availability.owns_car"
                      withoutError={true}
                    />
                    <FormikCheckbox
                      label="Motorbike"
                      name="availability.owns_motorbike"
                      withoutError={true}
                    />
                    <FormikCheckbox
                      label="Push Bike"
                      name="availability.owns_push_bike"
                      withoutError={true}
                    />
                  </CheckboxGroup>
                ) : (
                  ""
                )}
              </Col2>
              <Col2>
                <FormikText
                  label="Areas able to travel to"
                  name="availability.areas_can_travel_to"
                />
              </Col2>
              <h2>Experience</h2>
              <p>Please tick the types of work you are experienced with</p>
              <FieldSet>
                <CheckboxGroup>
                  <b>Driving</b>
                  <FormikCheckbox
                    label="Van"
                    name="experience.driving.van"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="7.5 T"
                    name="experience.driving.t75"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="HGV"
                    name="experience.driving.hgv"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Multi"
                    name="experience.driving.multi"
                    withoutError={true}
                  />
                </CheckboxGroup>
                <CheckboxGroup>
                  <b>Warehouse</b>
                  <FormikCheckbox
                    label="Van"
                    name="experience.warehouse.picker"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="7.5 T"
                    name="experience.warehouse.packer"
                    withoutError={true}
                  />
                </CheckboxGroup>
                <CheckboxGroup>
                  <b>Food Production</b>
                  <FormikCheckbox
                    label="Hygine"
                    name="experience.food_production.hygine"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Food Prep"
                    name="experience.food_production.food_prep"
                    withoutError={true}
                  />
                </CheckboxGroup>
                <CheckboxGroup>
                  <b>Fork-lift</b>
                  <FormikCheckbox
                    label="C/Balance"
                    name="experience.fork_lift.c_balance"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Reach"
                    name="experience.fork_lift.reach"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Narrow Isle"
                    name="experience.fork_lift.narrow_isle"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Side Forks"
                    name="experience.fork_lift.side_forks"
                    withoutError={true}
                  />
                </CheckboxGroup>
                <CheckboxGroup>
                  <b>Factory Work</b>
                  <FormikCheckbox
                    label="Assembly"
                    name="experience.factory_work.assembly"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Production"
                    name="experience.factory_work.production"
                    withoutError={true}
                  />
                </CheckboxGroup>
                <CheckboxGroup>
                  <b>Engineering</b>
                  <FormikCheckbox
                    label="CNC"
                    name="experience.engineering.cnc"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Welding"
                    name="experience.engineering.welding"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Gas Cutters"
                    name="experience.engineering.gas_cutters"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Press Work"
                    name="experience.engineering.press_work"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Fabriction"
                    name="experience.engineering.fabriction"
                    withoutError={true}
                  />
                </CheckboxGroup>
                <CheckboxGroup>
                  <b>Machine Operation</b>
                  <FormikCheckbox
                    label="Driller"
                    name="experience.machine_operation.driller"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Miller"
                    name="experience.machine_operation.miller"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Grinder"
                    name="experience.machine_operation.grinder"
                    withoutError={true}
                  />
                </CheckboxGroup>
                <CheckboxGroup>
                  <b>Other</b>
                  <FormikCheckbox
                    label="Foundry"
                    name="experience.other.foundry"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Shot Blaster"
                    name="experience.other.shot_blaster"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Team Leader"
                    name="experience.other.team_leader"
                    withoutError={true}
                  />
                  <FormikCheckbox
                    label="Assembler"
                    name="experience.other.assembler"
                    withoutError={true}
                  />
                </CheckboxGroup>
                <FormikText
                  label="Any further types of work experienced in not listed above"
                  name="experience.other.all_other"
                />
              </FieldSet>
              <h2>Employment History</h2>
              <EmploymentHistory />
              {errorText
                ? errorText.map(e => (
                    <Error style={{ marginTop: "1rem" }} key={e}>
                      {e}
                    </Error>
                  ))
                : null}
              <SButton type="submit" disabled={isSubmitting}>
                Submit
              </SButton>
            </Form>
          )}
        </Formik>
      </LCard>
    </Layout>
  )
}

export default PreRegistration
