export const initialValues = {
  personal_details: {
    title: "",
    first_name: "",
    surname: "",
    email: "",
    mobile: "",
    landline: "",
    national_insurance: "",
    address: "",
    smoker: "",
    safety_boots: "",
    safety_boots_size: "",
  },
  method_of_transport: [],
  availability: {
    available_from: "",
    shifts: {
      days: 0,
      nights: 0,
      weekends: 0,
    },
    driving_licence: "",
    own_transport: "",
    owns_car: 0,
    owns_motorbike: 0,
    owns_push_bike: 0,
    areas_can_travel_to: "",
  },
  experience: {
    driving: {
      van: 0,
      t75: 0,
      hgv: 0,
      multi: 0,
    },
    warehouse: {
      picker: 0,
      packer: 0,
    },
    food_production: {
      hygine: 0,
      food_prep: 0,
    },
    fork_lift: {
      c_balance: 0,
      reach: 0,
      narrow_isle: 0,
      side_forks: 0,
    },
    factory_work: {
      assembly: 0,
      production: 0,
    },
    engineering: {
      cnc: 0,
      welding: 0,
      gas_cutters: 0,
      press_work: 0,
      fabriction: 0,
    },
    machine_operation: {
      driller: 0,
      miller: 0,
      grinder: 0,
    },
    other: {
      foundry: 0,
      shot_blaster: 0,
      team_leader: 0,
      assembler: 0,
      all_other: "",
    },
  },
  references: [
    {
      name: "",
      company: "",
      job_title: "",
      contact: "",
      email: "",
      address: "",
    },
  ],
}
