import * as Yup from "yup"
import { dateRegex, phoneRegex, standardRequiredText } from "../../constants"
import { parseDateString } from "../../utils/form-data"
import moment from "moment"

export const validationSchema = Yup.object().shape({
  personal_details: Yup.object().shape({
    lead_source: Yup.string().required(standardRequiredText),
    title: Yup.string().required(standardRequiredText),
    first_name: Yup.string().required(standardRequiredText),
    surname: Yup.string().required(standardRequiredText),
    email: Yup.string()
      .email(
        "Must be a valid email address in the format 'example@example.com'"
      )
      .required(standardRequiredText),
    mobile: Yup.string()
      .matches(phoneRegex, "Must be a valid phone number")
      .required(standardRequiredText),
    landline: Yup.string().matches(phoneRegex, "Must be a valid phone number"),
    national_insurance: Yup.number()
      .integer()
      .required(standardRequiredText),
    address: Yup.string().required(standardRequiredText),
    smoker: Yup.string().required(standardRequiredText),
    safety_boots: Yup.string().required(standardRequiredText),
    safety_boots_size: Yup.string(),
  }),
  method_of_transport: Yup.array()
    .min(1)
    .required(standardRequiredText),
  availability: Yup.object().shape({
    available_from: Yup.string().required(standardRequiredText),
    shifts: Yup.object().shape({
      days: Yup.number(),
      nights: Yup.number(),
      weekends: Yup.number(),
    }),
    driving_licence: Yup.string().required(standardRequiredText),
    own_transport: Yup.string(),
    owns_car: Yup.number(),
    owns_motorbike: Yup.number(),
    owns_push_bike: Yup.number(),
    areas_can_travel_to: Yup.string().required(standardRequiredText),
  }),
  experience: Yup.object().shape({
    driving: Yup.object().shape({
      van: Yup.number(),
      t75: Yup.number(),
      hgv: Yup.number(),
      multi: Yup.number(),
    }),
    warehouse: Yup.object().shape({
      picker: Yup.number(),
      packer: Yup.number(),
    }),
    food_production: Yup.object().shape({
      hygine: Yup.number(),
      food_prep: Yup.number(),
    }),
    fork_lift: Yup.object().shape({
      c_balance: Yup.number(),
      reach: Yup.number(),
      narrow_isle: Yup.number(),
      side_forks: Yup.number(),
    }),
    factory_work: Yup.object().shape({
      assembly: Yup.number(),
      production: Yup.number(),
    }),
    engineering: Yup.object().shape({
      cnc: Yup.number(),
      welding: Yup.number(),
      gas_cutters: Yup.number(),
      press_work: Yup.number(),
      fabriction: Yup.number(),
    }),
    machine_operation: Yup.object().shape({
      driller: Yup.number(),
      miller: Yup.number(),
      grinder: Yup.number(),
    }),
    other: Yup.object().shape({
      foundry: Yup.number(),
      shot_blaster: Yup.number(),
      team_leader: Yup.number(),
      assembler: Yup.number(),
      all_other: Yup.string(),
    }),
  }),
  employment_history: Yup.object().shape({
    company_1: Yup.object().shape({
      company_name: Yup.string().required(standardRequiredText),
      job_title: Yup.string().required(standardRequiredText),
      date_from: Yup.string()
        .matches(dateRegex, "Must be a valid date in the format dd/mm/yyyy")
        .required(standardRequiredText),
      date_to: Yup.string()
        .matches(dateRegex, "Must be a valid date in the format dd/mm/yyyy")
        .required(standardRequiredText),
      reason_for_leaving: Yup.string(),
      duties: Yup.string(),
    }),
    company_2: Yup.object().shape({
      company_name: Yup.string(),
      job_title: Yup.string(),
      date_from: Yup.string().matches(
        dateRegex,
        "Must be a valid date in the format dd/mm/yyyy"
      ),
      date_to: Yup.string().matches(
        dateRegex,
        "Must be a valid date in the format dd/mm/yyyy"
      ),
      reason_for_leaving: Yup.string(),
      duties: Yup.string(),
    }),
    company_3: Yup.object().shape({
      company_name: Yup.string(),
      job_title: Yup.string(),
      date_from: Yup.string().matches(
        dateRegex,
        "Must be a valid date in the format dd/mm/yyyy"
      ),
      date_to: Yup.string().matches(
        dateRegex,
        "Must be a valid date in the format dd/mm/yyyy"
      ),
      reason_for_leaving: Yup.string(),
      duties: Yup.string(),
    }),
  }),
})
